<template>
  <v-dialog :value="value" @input="$emit('input', false)" v-bind="$attrs">
    <v-card>
      <v-toolbar flat dense>
        <v-toolbar-title>{{ title }}</v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="cancel">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <slot />
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn :color="confirmColor" text @click="confirm">{{
          confirmText
        }}</v-btn>
        <v-btn :color="abortColor" text @click="cancel">{{ abortText }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ConfirmDialog',
  components: {},
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'Bestätigung',
    },
    confirmText: {
      type: String,
      default: 'Ok',
    },
    confirmColor: {
      type: String,
      default: 'success',
    },
    abortText: {
      type: String,
      default: 'Abbrechen',
    },
    abortColor: {
      type: String,
      default: 'secondary',
    },
  },
  data() {
    return {
      dialog: false,
    }
  },
  methods: {
    cancel() {
      this.$emit('input', false)
      this.$emit('cancel')
    },
    confirm() {
      this.$emit('input', false)
      this.$emit('confirm')
    },
  },
}
</script>

