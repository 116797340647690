<template>
  <div class="dragndrop">
    <label>{{ label }}</label>
    <form
      enctype="multipart/form-data"
      novalidate
      v-if="!value"
      @dragover="dragStart"
      @dragenter="dragStart"
      @dragleave="dragEnd"
      @dragend="dragEnd"
      @drop="dragEnd"
    >
      <div
        :class="{
          dropzone: true,
          disabled: disabled,
          'is-dragover': dragActive,
        }"
      >
        <input
          type="file"
          class="input-file"
          ref="fileInput"
          :accept="accept"
          :disabled="disabled"
          :tabindex="tabindex"
          @change="onFileChange"
          @focus="dragStart"
          @blur="dragEnd"
        />
        <p v-if="!filename" class="subheading text-center my-2">
          Drag &amp; Drop <br />oder klicken um Datei zu wählen
        </p>
        <p v-else class="subheading text-center my-2">
          {{ filename }}
          <v-progress-linear :indeterminate="true" class="mt-2" />
        </p>
      </div>
    </form>

    <div v-else class="current-file">
      <v-btn
        block
        text
        large
        target="blank"
        :href="viewUrl"
        class="grey--text text--darken-2 py-4 my-0"
      >
        <v-icon>mdi-file-document-outline</v-icon>
        <span class="subheading ml-2 mt-1">Anzeigen</span>
      </v-btn>
      <v-btn
        text
        icon
        absolute
        color="error"
        class="mr-0"
        @click="$emit('remove')"
        :disabled="disabled"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>

export default {
  name: 'FileDragDrop',
  components: {},
  props: {
    label: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Object],
      default: '',
    },
    accept: {
      type: String,
      default: '*',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    tabindex: {
      type: [Number, String],
      default: null,
    },
  },
  data() {
    return {
      dragActive: false,
      filename: '',
    }
  },
  computed: {
    isAdvancedUpload() {
      const div = document.createElement('div')
      return (('draggable' in div) || ('ondragstart' in div && 'ondrop' in div)) && 'FormData' in window && 'FileReader' in window
    },
    viewUrl() {
      return `${this.value}?t=${(new Date()).getTime()}`
    },
  },
  watch: {
    value(val) {
      if (!val) {
        this.filename = ''
      }
    },
  },
  methods: {
    onFileChange(e) {
      const files = e.target.files || e.dataTransfer.files
      const form = new FormData()
      if (!files.length) {
        return
      }
      for (const file of files) {
        this.filename = file.name
        form.append('data', file, file.name)
      }
      this.$emit('formData', form)
    },
    dragStart() { this.dragActive = true },
    dragEnd() { this.dragActive = false },
  },
}
</script>


<style lang="scss">
$dragover-color: #e0f2f1;

.dragndrop {
  label {
    display: inline-block;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.54);
  }
}

.dropzone {
  outline: 2px dashed lighten(grey, 20);
  outline-offset: -5px;
  color: dimgray;
  padding: 10px;
  min-height: 80px;
  position: relative;

  &:hover,
  &:focus {
    background: $dragover-color;
  }

  .input-file {
    opacity: 0;
    margin: -5px 0 0 -5px;
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    position: absolute;
    border: 1px dashed red;
    cursor: pointer;
  }
}

.dropzone.disabled {
  outline: 2px dashed lighten(grey, 30);

  p {
    color: lighten(#696969, 20);
  }

  .input-file {
    cursor: default;
  }

  &:hover {
    background: none;
  }
}

.dropzone.is-dragover {
  background: $dragover-color;
}

.current-file {
  position: relative;
  border: 0.5px solid lighten(grey, 20);
  border-radius: 2px;

  a {
    .btn__content {
      justify-content: flex-start;
    }
  }
  button {
    top: 0;
    bottom: 0;
    right: 8px;
    margin: auto;
  }
}
</style>

